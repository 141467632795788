
import { defineComponent } from 'vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'

export default defineComponent({
  components: {
    DssIcon,
    ButtonIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    isDisplay: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
})
