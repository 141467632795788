import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14a50302"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification-message-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_ctx.isDisplay)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["notification-message", _ctx.type])
      }, [
        _createElementVNode("span", null, [
          (_ctx.type === 'notice' || _ctx.type === 'information')
            ? (_openBlock(), _createBlock(_component_DssIcon, {
                key: 0,
                icon: "balloon-exclamationmark"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1),
        (_ctx.type === 'notice' || _ctx.type === 'success' || _ctx.type === 'error')
          ? (_openBlock(), _createBlock(_component_ButtonIcon, {
              key: 0,
              icon: "xmark-circle",
              styled: "is-none",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove', _ctx.id))),
              onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('remove', _ctx.id)), ["prevent"]), ["space","enter"])),
              "aria-label": "閉じる"
            }))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}