import { getDataBackground } from '@/helpers/util/webApiUtil'
import { store } from '@/store'
import { v4 as uuidv4 } from 'uuid'
export interface uploadFileData {
  fileName: string,
  s3UploadFilePath: string,
  fileData: File | null
}

const packFileUploadData = async (fileList: any, s3DirName: string) => {
  const fileNameList = []
  for (const file of fileList) {
    fileNameList.push(file.name)
  }
  console.log(fileNameList)
  await store.dispatch('setFileNameList', fileNameList)
}
/*
 * AWS SDK Settings
 */
// eslint-disable-next-line
const AWS = require("aws-sdk");
AWS.config.region = 'ap-northeast-1'
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.VUE_APP_IDENTITY,
})
const s3 = new AWS.S3({ apiVersion: '2006-03-01' })
export const s3upload = async (fileList: uploadFileData[]) => {
  const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL
  const url = `${BASE_URL}/outer/session/check`
  // セッションを維持するためバックグラウンドで定期的にコールする。
  const interval = setInterval(() => getDataBackground(url), 5 * 60 * 1000)
  const uploadFileList = fileList
  let i = 0
  const uuid = uuidv4()
  for (const file of fileList) {
    if (file.fileData) {
      const key = `${uuid}/${file.fileName}`
      const params = {
        Bucket: process.env.VUE_APP_DMS_FILE_UPLOAD_BUCKET,
        Key: key,
        Body: file.fileData,
      }
      // 分割アップロードの設定
      const options = { partSize: 10 * 1024 * 1024, queueSize: 1 }
      await s3.upload(
        params,
        options,
        function (err: any, data: any) {
          console.log(err, data)
        }
      ).promise()
      uploadFileList[i] = {
        fileName: file.fileName,
        s3UploadFilePath: `${uuid}/${file.fileName}`,
        fileData: null,
      }
    }
    i += 1
  }
  clearInterval(interval)
  await store.dispatch('setS3UploadFileList', uploadFileList)
}
